import { Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { IOrganizationSeat, Plan } from '../../../../types'
import { format } from 'date-fns'
import { requestPlanChange } from '../../../../api'

interface IModalInput {
  seat?: IOrganizationSeat
  newPlan: Plan
  changeDate: Date
  closeModalCallback: () => void
}

export const ConfirmChangeModal: React.FC<IModalInput> = (input) => {
  const { seat, newPlan, changeDate, closeModalCallback } = input
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  function closeModal() {
    closeModalCallback()
  }

  function requestChange() {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (seat && newPlan && changeDate) {
      requestPlanChange(seat.id, newPlan.id, changeDate)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Plan change request sent`
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)
          toast({
            status: 'error',
            description: `Failed to send change request`
          })
        })
    }
  }

  if (!!!seat) {
    return null
  }
  return (
    <BaseModal
      title="Confirm Change"
      isOpen={seat !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={'Confirm'}
      primaryAction={requestChange}
      secondaryAction={closeModal}
      isSubmitting={isSubmitting}
      isLoading={isSubmitting}
    >
      <Text>
        Are you sure you want to change your membership from `
        {seat.active_plan?.name}` to `{newPlan.name}` starting on{' '}
        {format(changeDate, 'dd/MM/yyyy')}?
      </Text>
    </BaseModal>
  )
}
