import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import { format } from 'date-fns'
import queryString from 'query-string'
import { encodeQueryParams } from 'serialize-query-params'
import { UserProfile } from './interfaces'
import {
  ApprovalStatusChoices,
  IBillingAccountDetail,
  IAddonSubscriptionRequest,
  IBillingCredit,
  IBlogPost,
  IBond,
  ICommunityProfile,
  ICreateBookingRequest,
  IDailyFiveContent,
  IInvitation,
  ILocationOpeningHours,
  ILocationUpdateCreate,
  IMeetingRoom,
  INewContent,
  INewMeetingRoom,
  INewOffice,
  INewPlan,
  INewWorkspace,
  Interest,
  IOneTimeCharge,
  IOrganization,
  IOrganizationalUnit,
  IPaginatedDailyFive,
  IPaginatedQuery,
  IPlanCategory,
  IPlanCategoryInput,
  IPlatformTenantUpdate,
  IPreviewBookingCost,
  IUpdateAPI,
  IWorkspace,
  IXeroAccountCode,
  IXeroIntegrationSettingsUpdate,
  Location,
  MeResponse,
  Plan,
  PlanCategory,
  Skill,
  TenantEftAccount,
  ILocationBusinessHours,
  IUpdateOrganizationProfile,
  DirectoryOrganizationResult,
  DirectoryOrganizationalUnitResult,
  DirectoryMemberResult,
  IOrganizationalUnitDirectoryPreferences,
  IPlanChangeRequest,
  ILocationBooking,
  IBasicOffice,
  ILocationAdminDTO,
  ILocationAdmin,
  IOrganizationalUnitFilterableSelect,
  IEventsFilter,
  UpdateObjectBillingAccount,
  ICreateOneTimeCharge,
  IAccountDeletionRequestData,
  AccountDeletionResponse,
  IAccountDeletionRequest
} from './types'
import {
  filterLocationsQueryParams,
  filterMyConnectionsQueryParams,
  filterOrganizationalUnitQueryParams,
  filterOfficesQueryParams,
  filterOrganizationQueryParams,
  planChangesReportQueryParams,
  filterCalendarEventsQueryParams
} from './utils/queryParams'

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST
axios.defaults.headers.post['Content-Type'] = 'application/json'

const { stringify } = queryString

/**
 * a wrapper around Axios.request to make API calls using the Django token
 * @param url the URL to make the request to - only the path eg ('/invoices'), not the full URL
 * @param method the HTTP method to use
 */
export const request = <T>(
  url: string,
  method: Method,
  config?: Partial<AxiosRequestConfig>
): Promise<AxiosResponse<T>> => {
  const token = window.localStorage.getItem('Token')
  const headers = token ? { Authorization: `Token ${token}` } : {}

  return axios.request<T>({
    url,
    method,
    ...config,
    headers: { ...headers, ...config?.headers }
  })
}

export const createNewUser = (newUser: Partial<NewUserRequest>) =>
  axios.post('/api/v1/rest-auth/registration/', newUser)

export const login = ({
  email,
  password
}: {
  email: string
  password: string
}) =>
  axios.post<{ key: string }>('/api/v1/rest-auth/login/', {
    username: email,
    password
  })

export const getMe = ({ token }: { token: string | null }) =>
  axios.get('/api/v1/users/me/', {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: MeResponse }>

export interface NewUserRequest extends UserDetailsRequest {
  email: string
  username: string
  password1: string
  password2: string
  first_name: string
  last_name: string
  postcode: number
  mobile_number: string
  start_date?: string
  invite_token: string | null
  location_id?: number
  plan?: string | null
  is_external_signup?: boolean
  platform_tenant?: number
}

interface UserDetailsRequest {
  id?: number
  plan?: string | null
  personal_email?: string
  mobile_number?: string
  postcode?: number
  start_date?: string
  job_title?: string
  company?: string
  interests?: number[]
  skills?: number[]
  about_you?: string
  agreed_tc?: number
}
export interface GetUserProfileResponse {
  plans: Plan[] | []
  id?: number
  profile_picture_url?: string
  mobile_number?: string
  personal_email?: string
  postcode?: string
  start_date?: string
  job_title?: string
  interests?: Interest[]
  skills?: Skill[]
  about_you?: string
  wifi_password?: string
  agreed_tc?: boolean
  signup_status?: string
  linkedin_url?: string
  is_migrated_from_nexudus?: boolean
}
export interface GetUserResponse {
  url: string
  id: number
  email: string
  first_name: string
  last_name: string
  username: string
  name: string
  user_profile: {
    id: number
    profile_picture_url: any
    mobile_number: any
    postcode: any
    start_date: any
    about_you: any
    signup_status: string
    approved_on: string
    gst_chargeable: true
    is_linked_to_nexudus: false
    onboarded_on: any
    last_charged_on: any
    plans: Plan[]
    approved_by: {
      id: number
      last_login: string
      is_superuser: false
      username: string
      first_name: string
      last_name: string
      email: string
      is_staff: true
      is_active: true
      date_joined: string
      user_profile: number
      groups: any[]
      user_permissions: any[]
    }
    onboarded_by: any
    interests: Interest[]
    skills: Skill[]
  }
}

export enum InvoiceStatus {
  Building = 'PENDING',
  Issued = 'ISSUED',
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Paid = 'PAID',
  Credited = 'CREDITED',
  Cancelled = 'CANCELLED',
  Preview = 'PREVIEW'
}

export enum RefundStatus {
  Created = 'CREATED',
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Refunded = 'REFUNDED',
  Cancelled = 'CANCELLED'
}

export interface Invoice {
  id: number
  organization: number
  user: number
  subtotal_charged: string
  tax_charged: string
  amount_charged: string
  amount_paid?: string
  plan_title: string
  status: InvoiceStatus
  paid_on?: string
  issued_on: string
  subtotal: number
  invoice_history: number[]
}
export const updateUserDetails = (
  userDetails: Partial<GetUserProfileResponse>,
  { token }: { token: string | null }
) =>
  axios.patch(`/api/v1/profile/`, userDetails, {
    headers: { Authorization: `Token ${token}` }
  })

export const updateOrganizationName = (
  organizationId: number,
  organizationName: string
) =>
  request(`/api/v1/organizations/${organizationId}/`, 'PATCH', {
    data: { name: organizationName }
  })

export const updateOrganizationProfile = (
  orgProfileId: number,
  profileData: IUpdateOrganizationProfile
) => {
  const fData = new FormData()
  if (profileData.logo_image_file) {
    fData.append('logo_image_file', profileData.logo_image_file as Blob)
  } else if (profileData.overview) {
    fData.append('overview', profileData.overview ?? '')
  } else {
    const profile = {
      linkedin_url: profileData.linkedin_url ?? '',
      industries: profileData.industries,
      website: profileData.website ?? '',
      mobile_number: profileData.mobile_number ?? '',
      empty_industries: profileData.empty_industries
    }

    return request(`/api/v1/organization-profile/${orgProfileId}/`, 'PATCH', {
      data: profile
    })
  }
  return request(`/api/v1/organization-profile/${orgProfileId}/`, 'PATCH', {
    data: fData
  })
}

export const setOrganizationEFTPermitted = (
  organizationId: number,
  permitted: boolean,
  token: string | undefined
) =>
  axios.patch(
    `/api/v1/organizations/${organizationId}/`,
    { eft_permitted: permitted },
    {
      headers: {
        Authorization: `Token ${token}`
      }
    }
  )

export const updateOrganizationHomeLocation = (
  organizationId: number,
  data: Location
) =>
  request(
    `/api/v1/organizations/${organizationId}/set_default_location/`,
    'POST',
    {
      data: { location: data.id }
    }
  )

export const updateUserBio = (
  id: number,
  about_you: string,
  { token }: { token: string | null }
) =>
  axios.patch(
    `/api/v1/profile/${id}/`,
    { about_you: about_you },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )
export const getUser = (id: number, { token }: { token: string | null }) =>
  axios.get(`/api/v1/users/${id}/`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: GetUserResponse }>

export const getUserDetails = ({ token }: { token: string | null }) =>
  axios.get(`/api/v1/profile/`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: UserProfile[] }>

export const addInterestUserProfile = (interest: Interest) =>
  request(`/api/v1/profile/add_interest_to_profile/`, 'POST', {
    data: interest
  }) as Promise<AxiosResponse<any>>

export const removeInterestUserProfile = (interest: Interest) =>
  request(`/api/v1/profile/remove_interest_from_profile/`, 'POST', {
    data: interest
  }) as Promise<AxiosResponse<any>>

export const setReceivesMembershipEmails = (
  user_profile_id: number,
  value: boolean
) =>
  request(
    `/api/v1/profile/${user_profile_id}/set_receives_membership_emails/`,
    'POST',
    {
      data: { value }
    }
  ) as Promise<AxiosResponse<any>>

export const getInterests = () => axios.get('/api/v1/interests/')

export const getSkills = () => axios.get('/api/v1/skills/')

export const getMyInvoices = (organization: number) =>
  request<Invoice[]>(`/api/v1/invoices/?organization=${organization}`, 'GET')

export const getInvoice = ({
  token,
  invoiceId
}: {
  token: string | null
  invoiceId: string
}) =>
  axios.get(`/api/v1/invoices/${invoiceId}/`, {
    headers: { Authorization: `Token ${token}` }
  }) as Promise<{ data: Invoice }>

export const initiateInvoiceCharge = async (
  invoiceId: number,
  { token }: { token: string | null }
) =>
  axios
    .post(
      `/api/v1/invoices/${invoiceId}/initiate_invoice_payment/`,
      {},
      {
        headers: { Authorization: `Token ${token}` }
      }
    )
    .then((result: any) => {
      return result
    })

export const initiateReattemptInvoiceRefund = (invoiceId: number) =>
  request<string>(
    `/api/v1/invoices/${invoiceId}/re_attempt_refund_invoice/`,
    'POST'
  )

export const initiateCancelRefundInvoice = (invoiceId: number) =>
  request<string>(
    `/api/v1/invoices/${invoiceId}/cancel_refund_invoice/`,
    'POST'
  )

export const updateInvoiceStatus = (
  invoiceId: number,
  status: InvoiceStatus
) => {
  return request(`/api/v1/invoices/${invoiceId}/`, 'PATCH', {
    data: { status: status }
  })
}

export const onboardMember = (
  tenantId: number,
  id: number,
  { token }: { token: string | null }
) =>
  axios.post(
    `/api/v1/users-awaiting-onboarding/${id}/onboard_user/?tenant=${tenantId}`,
    {},
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const getNotes = (id: number, { token }: { token: string | null }) => {
  axios.post(
    `/api/v1/notes/user_notes/`,
    { user_id: id },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )
}

export const makeNote = (memberId: number, note: string) =>
  request(`/api/v1/notes/`, 'POST', {
    data: { spacecubed_user: memberId, note }
  })

export interface IRequestChangePlan {
  new_plan: number
  target_ou: number
  change_date: string
}

export const adminRequestChangePlan = (data: IRequestChangePlan) =>
  request('/api/v1/change-requests/request_change_plan/', 'POST', {
    data
  })

export interface INewMemberInvite {
  plan: number
  location_id: number
  invitation_email: string
  start_date: string
  override_value?: any
  is_first_subscription_non_chargeable?: boolean
  override_reason?: string
}

export const newMemberInvite = (data: INewMemberInvite) =>
  request('/api/v1/invitations/new_member_invite/', 'POST', {
    data
  })

export const validateVerificationEmailToken = (email: string, token: string) =>
  request('/api/v1/verification/validate_email_token/', 'POST', {
    data: {
      email,
      token
    }
  })
export const sendEmailVerificationToken = (email) =>
  request<Boolean>('/api/v1/verification/send_validation_token/', 'POST', {
    data: { email }
  })
export interface ICommunityMemberInvite {
  community_id: number
  invitation_email: string
}

//get the community profile which includes the saved posts
export const getCommunityProfile = () =>
  request(`/api/v1/community-profile/`, 'GET')

export const saveContentToProfile = (contentId: number) =>
  request(`/api/v1/daily-five/content/${contentId}/save_post/`, 'POST', {})
export const removeContentFromProfile = (contentId: number) =>
  request(
    `/api/v1/daily-five/content/${contentId}/remove_saved_post/`,
    'POST',
    {}
  )
export const getContent = (contentId: number) =>
  request(`/api/v1/daily-five/content/${contentId}/`, 'GET')
export const addComment = (contentId: number, data: { body: string }) =>
  request(`/api/v1/daily-five/content/${contentId}/add_comment/`, 'POST', {
    data
  })
export const getComments = (contentId: number) =>
  request(`/api/v1/daily-five/content/${contentId}/comments/`, 'GET')

export const postContent = (data: INewContent) => {
  const fData = new FormData()
  fData.append('title', data.title)
  fData.append('content_type', data.content_type)
  data.summary && fData.append('summary', data.summary)
  data.url && fData.append('url', data.url)
  data.date && fData.append('date', data.date)
  data.start_time && fData.append('start_time', data.start_time)
  data.finish_time && fData.append('finish_time', data.finish_time)
  data.location && fData.append('location', data.location)
  data.location_venue &&
    fData.append('location_venue', `${data.location_venue.id}`)
  data.image && fData.append('image', data.image as Blob)
  data.add_zoom_meeting &&
    fData.append('add_zoom_meeting', data.add_zoom_meeting.toString())
  data.platform_tenant &&
    fData.append('platform_tenant', data.platform_tenant.toString())
  data.interests && fData.append('interests', JSON.stringify(data.interests))
  return request<IDailyFiveContent>(`/api/v1/daily-five/content/`, 'POST', {
    data: fData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export const zoomCallback = (data: { code: string }) =>
  request(`/api/v1/zoom-callback/`, 'POST', {
    data
  })

export const importAccountsFromXero = (
  tenantId: number,
  accountCodes: IXeroAccountCode[]
) => {
  return request<IXeroAccountCode[]>(
    `/api/v1/platform-tenants/${tenantId}/import_account_code/`,
    'POST',
    { data: accountCodes }
  )
}

export const setPaymentAccountForXero = (
  tenantId: number,
  accountCode: IXeroAccountCode
) => {
  return request(
    `/api/v1/platform-tenants/${tenantId}/set_payment_account/`,
    'POST',
    { data: accountCode }
  )
}

export const updateXeroIntegrationSettings = (
  tenantId: number,
  settings: IXeroIntegrationSettingsUpdate
) => {
  return request(
    `/api/v1/platform-tenants/${tenantId}/xero_integration_settings/`,
    'POST',
    { data: settings }
  )
}
export const updateHumanitixAPI = (
  settingsId: number,
  apiDetails: IUpdateAPI
) => {
  return request(`/api/v1/integrations/humanitix/${settingsId}/`, 'PATCH', {
    data: apiDetails
  })
}
export const tenantXeroRegistration = (tenantId: number, code: string) => {
  return request(
    `/api/v1/platform-tenants/${tenantId}/xero_authorization_callback/?code=${code}`,
    'POST',
    { data: {} }
  )
}
export const createZoomMeeting = (data: {}) =>
  request(`/api/v1/zoom/meetings/`, 'POST', {
    data
  })
export interface IContentUpdate {
  approval_status: ApprovalStatusChoices
  active_to_show_in_future_daily_five?: boolean
  interests?: Interest[]
}

export const adminUpdateContent = (data: IContentUpdate, contentId: number) =>
  request(`/api/v1/daily-five/content/${contentId}/`, 'PATCH', {
    data
  })

export const updateCommunityProfile = (
  data: ICommunityProfile,
  profileId: number
) =>
  request(`/api/v1/community-profile/${profileId}/`, 'PATCH', {
    data
  })
export const updatePlatformTenant = (
  data: IPlatformTenantUpdate,
  tenantId: number
) => {
  const fData = new FormData()
  data.brand_primary_color &&
    fData.append('brand_primary_color', data.brand_primary_color)
  data.logoLongWhiteImageFile &&
    fData.append('logo_long_white', data.logoLongWhiteImageFile as Blob)
  data.logoLongImageFile &&
    fData.append('logo_long', data.logoLongImageFile as Blob)
  data.logoSquareImageFile &&
    fData.append('logo_square', data.logoSquareImageFile as Blob)
  data.hubspot_help_widget_code !== undefined &&
    fData.append('hubspot_help_widget_code', data.hubspot_help_widget_code)
  data.user_chatbot_iframe_html !== undefined &&
    fData.append('user_chatbot_iframe_html', data.user_chatbot_iframe_html)
  data.support_link && fData.append('support_link', data.support_link)
  data.contact_form_url &&
    fData.append('contact_form_url', data.contact_form_url)
  data.business_name && fData.append('business_name', data.business_name)
  data.abn && fData.append('abn', data.abn)
  data.address && fData.append('address', data.address)
  data.invoiceLogoLongImageFile &&
    fData.append('invoice_logo_long', data.invoiceLogoLongImageFile as Blob)
  data.emailMemberAcceptedBannerImageFile &&
    fData.append(
      'email_member_accepted_banner',
      data.emailMemberAcceptedBannerImageFile as Blob
    )
  data.emailNewNotificationBannerImageFile &&
    fData.append(
      'email_new_notification_banner',
      data.emailNewNotificationBannerImageFile as Blob
    )
  data.emailMeetingRoomBookingBannerImageFile &&
    fData.append(
      'email_meeting_room_booking_banner',
      data.emailMeetingRoomBookingBannerImageFile as Blob
    )

  data.email_highlight_theme_color &&
    fData.append(
      'email_highlight_theme_color',
      data.email_highlight_theme_color
    )
  data.email_signature && fData.append('email_signature', data.email_signature)
  data.email_company_name &&
    fData.append('email_company_name', data.email_company_name)
  data.social_account_facebook &&
    fData.append('social_account_facebook', data.social_account_facebook)
  data.social_account_instagram &&
    fData.append('social_account_instagram', data.social_account_instagram)
  data.social_account_linkedin &&
    fData.append('social_account_linkedin', data.social_account_linkedin)
  data.social_account_twitter &&
    fData.append('social_account_twitter', data.social_account_twitter)
  data.email_from_address &&
    fData.append('email_from_address', data.email_from_address)
  data.email_from_address_billing &&
    fData.append('email_from_address_billing', data.email_from_address_billing)
  data.email_welcome_message &&
    fData.append('email_welcome_message', data.email_welcome_message)

  return request(`/api/v1/platform-tenants/${tenantId}/`, 'PATCH', {
    data: fData
  })
}

export const sendWelcomeEmailTest = (tenantId: number) =>
  request<{ url: string }>(
    `api/v1/platform-tenants/${tenantId}/sendWelcomeEmailTest/`,
    'POST'
  )

export const fetchLocationBookingSettings = (locationId: number) =>
  request<ILocationBooking>(
    `api/v1/locations/${locationId}/booking_settings`,
    'GET'
  )

export const updateLocationBookingSettings = (
  locationId: number,
  settings: ILocationBooking
) => {
  const fData = new FormData()
  settings.external_booking_message &&
    fData.append('external_booking_message', settings.external_booking_message)
  settings.booking_about &&
    fData.append('booking_about', settings.booking_about)
  settings.booking_rules &&
    fData.append('booking_rules', settings.booking_rules)
  return request(`api/v1/locations/${locationId}/booking_settings/`, 'PATCH', {
    data: fData
  })
}
export const updateExternalBookingMessage = (
  locationId: number,
  external_booking_message: string
) =>
  request<{ url: string }>(
    `api/v1/locations/${locationId}/external_booking_message/`,
    'PATCH',
    {
      data: { external_booking_message }
    }
  )

export const updateDayBusinessHours = (
  location: number,
  business_hours: ILocationBusinessHours
) =>
  request<string>(`api/v1/locations/update_day_business_hours/`, 'POST', {
    data: {
      location,
      ...business_hours
    }
  })

export const createOrUpdateLocation = (
  data: ILocationUpdateCreate,
  tenantId: number
) => {
  const fData = new FormData()
  data.id !== undefined && fData.append('id', data.id.toString())
  fData.append('name', data.name)
  fData.append('address', data.address)
  fData.append('city', data.city.toString())
  data.accounting_department_code &&
    fData.append('accounting_department_code', data.accounting_department_code)
  fData.append('order_of_preference', data.order_of_preference.toString())
  fData.append('hidden_to_signup', data.hidden_to_signup.toString())
  data.thumbnail && fData.append('thumbnail', data.thumbnail as Blob)

  return request(`/api/v1/platform-tenants/${tenantId}/locations/`, 'PUT', {
    data: fData
  })
}

export const updateLocationInvoicePrefix = (location: number, prefix: string) =>
  request(`/api/v1/locations/${location}/update_location_prefix/`, 'POST', {
    data: { location, prefix }
  })

export const updateOpeningHours = (data: ILocationOpeningHours) => {
  return request(`/api/v1/locations/update_opening_hours/`, 'POST', {
    data
  })
}

export const stripeOnboardingLink = (tenantId: number) =>
  request<{ url: string }>(
    `api/v1/platform-tenants/${tenantId}/stripe_onboarding_link/`,
    'GET'
  )
export interface IRequestCancelPlan {
  target_ou: number
  change_date: string
}

export const adminRequestCancelPlan = (data: IRequestCancelPlan) =>
  request('/api/v1/change-requests/request_cancel_plan/', 'POST', {
    data
  })

export const linkXeroToOrganization = (
  { organizationId, xeroId },
  { token }: { token: string | null }
) =>
  axios.patch(
    `api/v1/billing-accounts/${organizationId}/`,
    {
      xero_contact_id: xeroId
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const updateBillingAccount = (
  billingAccountId,
  billingAccountInfo: UpdateObjectBillingAccount
) =>
  request(`api/v1/billing-accounts/${billingAccountId}/`, 'PATCH', {
    data: { ...billingAccountInfo }
  })

export const getOrgBillingCredits = (orgId: number) =>
  request(`api/v1/billing-credits/?organization=${orgId}`, 'GET') as Promise<
    AxiosResponse<IBillingCredit[]>
  >

export const createBillingCredit = (billingCredit: IBillingCredit) =>
  request(`api/v1/billing-credits/`, 'POST', {
    data: billingCredit
  })

export const updateBillingCredit = (
  billingCreditId: number,
  billingCredit: IBillingCredit
) =>
  request(`api/v1/billing-credits/${billingCreditId}/`, 'PATCH', {
    data: billingCredit
  })

export const getOrgOneTimeCharges = (orgId: number) =>
  request(`api/v1/one-time-charges/?organization=${orgId}`, 'GET') as Promise<
    AxiosResponse<IOneTimeCharge[]>
  >

export const createOneTimeCharge = (OneTimeCharge: ICreateOneTimeCharge) =>
  request(`api/v1/one-time-charges/`, 'POST', {
    data: OneTimeCharge
  })

export const deleteOneTimeCharge = (chargeId: number) => {
  return request<string>(`api/v1/one-time-charges/${chargeId}/`, 'DELETE')
}

export const updateOneTimeCharge = (
  chargeId: number,
  chargeData: Partial<IOneTimeCharge>
) =>
  request(`api/v1/one-time-charges/${chargeId}/`, 'PATCH', {
    data: chargeData
  })

export const getOrgBonds = (orgId: number) =>
  request<IBond[]>(`api/v1/bonds/?organization=${orgId}`, 'GET')

export const createBond = (bond: IBond) =>
  request(`/api/v1/bonds/`, 'POST', {
    data: bond
  })

export const updateBond = (bondId: number, bond: IBond) =>
  request(`/api/v1/bonds/${bondId}/`, 'PATCH', {
    data: bond
  })

export const getTermsAndConditions = () =>
  axios.get(`api/v1/terms-and-conditions/`)

export const bookMeetingRoom = (booking: ICreateBookingRequest) =>
  request(`api/v1/bookings/`, 'POST', {
    data: booking
  })

export const rescheduleMeetingRoom = (
  bookingId: number,
  booking: ICreateBookingRequest
) =>
  request(`api/v1/bookings/${bookingId}/`, 'PATCH', {
    data: booking
  })

export const getMeetingRoomTimelineByBooking = (bookingId: number) =>
  request<IMeetingRoom>(
    `/api/v1/meeting-rooms-bookings/get_meetingroom_timeline_by_booking/?booking=${bookingId}`,
    'GET'
  )

export const getMeetingRoomTimeline = (roomId: number, date: Date) =>
  request<IMeetingRoom>(
    `/api/v1/meeting-rooms-bookings/${roomId}/get_meetingroom_timeline/?date=${format(
      date,
      'yyyy-MM-dd'
    )}`,
    'GET'
  )

export const getWorkspaceRoomTimeline = (workspaceId: number, date: Date) =>
  request<IWorkspace>(
    `/api/v1/workspaces-bookings/${workspaceId}/get_workspace_timeline/?date=${format(
      date,
      'yyyy-MM-dd'
    )}`,
    'GET'
  )

export const getWorkspaceTimelineByBooking = (bookingId: number) =>
  request<IWorkspace>(
    `/api/v1/workspaces-bookings/get_workspace_timeline_by_booking/?booking=${bookingId}`,
    'GET'
  )

export const previewBookingCost = (data: ICreateBookingRequest) => {
  return request<IPreviewBookingCost>(`api/v1/bookings/preview_cost/`, 'POST', {
    data
  })
}

export const previewRescheduleBookingCost = (
  bookingId: number,
  data: ICreateBookingRequest
) => {
  return request<IPreviewBookingCost>(
    `api/v1/bookings/${bookingId}/preview_cost_rescheduling/`,
    'POST',
    {
      data
    }
  )
}
export const superAdminRequestCancelFullTeam = (
  orgId: number,
  cancellation_date: Date
) =>
  request(`api/v1/change-requests/request_cancel_full_team_plans/`, 'POST', {
    data: {
      organization: orgId,
      change_date: format(cancellation_date, 'yyyy-MM-dd')
    }
  })

export const saveBillingDetails = (
  sourceId: string | undefined,
  isEft: boolean,
  organizationId: number,
  { token }: { token: string | null }
) => {
  axios.defaults.xsrfCookieName = 'csrftoken'
  axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
  return axios.post(
    `/api/v1/payment-gateway/create/`,
    { sourceId, isEft, organizationId },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )
}

export const saveBillingDetailsConnect = (
  sourceId: string | undefined,
  isEft: boolean,
  organizationId: number,
  platformTenantId: number,
  setupIntent: string | undefined
) => {
  return request(`/api/v1/payment-gateway/create-connect/`, 'POST', {
    data: {
      sourceId,
      isEft,
      organizationId,
      tenantId: platformTenantId,
      setupIntent
    }
  })
}

export const getBillingAccount = (
  organizationId: number,
  platform_tenant: number
) =>
  request<IBillingAccountDetail>(
    `/api/v1/organizations/${organizationId}/get_billing_account/?platform_tenant=${platform_tenant}`,
    'GET'
  )
export const getBillingAccounts = (organizationId: number) =>
  request<IBillingAccountDetail[]>(
    `/api/v1/organizations/${organizationId}/get_billing_accounts/`,
    'GET'
  )

export const sendEmailForApproval = ({ token }: { token: string | null }) =>
  axios.post(
    `/api/v1/users/send_submission/`,
    {},
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const getInvoiceHTML = ({
  token,
  invoiceId
}: {
  token: string | null
  invoiceId: string
}) =>
  axios.get(`/api/v1/invoices/${invoiceId}/view/`, {
    headers: { Authorization: `Token ${token}` }
  })

export const getInvoicePDF = ({
  invoiceId,
  orgId
}: {
  invoiceId: string
  orgId: number
}) => {
  const token = window.localStorage.getItem('Token')
  let url = `/api/v1/invoices/${invoiceId}/view_pdf/?download=True`
  url = url.concat(orgId ? '&organizationId=' + orgId : '')
  return axios
    .get(url, {
      headers: { Authorization: `Token ${token}` },
      responseType: 'blob'
    })
    .then((response) => {
      // source: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743#file-download-file-js-L6
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Spacecubed_Invoice_${invoiceId}.pdf`)
      document.body.appendChild(link)
      link.click()
    })
}

export const exportInvoices = (
  locId?: number,
  status?: string,
  tenantId?: number
) => {
  request<BlobPart>(
    `/api/v1/invoices/export/?location=${locId}&status=${status}&tenant=${tenantId}`,
    'GET',
    {
      responseType: 'blob'
    }
  ).then((response) => {
    // source: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743#file-download-file-js-L6
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Invoice export.zip`)
    document.body.appendChild(link)
    link.click()
  })
}
export const exportInvoicesDeferred = (
  locId?: number,
  status?: string,
  tenantId?: number
) =>
  request(
    `/api/v1/invoices/export_deferred/?location=${locId}&status=${status}&tenant=${tenantId}`,
    'POST'
  )
export const setUserOrganizationUnit = ({
  token,
  org_unit,
  plan
}: {
  token: string | null
  org_unit: number | null
  plan
}) =>
  axios.post(
    `/api/v1/users/set_org_unit/`,
    {
      org_unit,
      plan
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const sendEmailForPasswordReset = async (email: string) =>
  axios.post(`/api/v1/rest-auth/password/reset/`, {
    email
  })

export const PasswordResetConfirmation = async ({
  uid,
  resetToken,
  new_password1,
  new_password2
}: {
  uid?: any
  resetToken: string | null
  new_password1: string
  new_password2: string
}) =>
  axios.post(
    `/api/v1/rest-auth/password/reset/confirm/`,
    {
      uid,
      token: resetToken,
      new_password1,
      new_password2
    },
    {
      headers: {
        /*Authorization: `Dont pass authorization through, cannot be authorized if resetting password`*/
      }
    }
  )

export const approveOrgUnitRequest = ({
  id,
  isFirstSubscriptionNonChargeable,
  nonChargeable,
  isNonTaxable,
  overrideValue,
  token
}: {
  id: number
  isFirstSubscriptionNonChargeable: boolean
  nonChargeable: boolean
  isNonTaxable?: boolean
  overrideValue?: number
  token: string | null
}) =>
  axios.post(
    `/api/v1/change-requests/approve_change_request/`,
    {
      change_request_id: id,
      props: {
        is_first_subscription_non_chargeable: isFirstSubscriptionNonChargeable,
        non_chargeable: nonChargeable,
        is_non_taxable: isNonTaxable,
        override_value: overrideValue
      }
    },
    {
      headers: { Authorization: `Token ${token}` }
    }
  )

export const approvePlanRequest = (id: number) =>
  request('/api/v1/change-requests/approve_change_request/', 'POST', {
    data: { change_request_id: id }
  })

export const declinePlanRequest = (id: number) =>
  request('/api/v1/change-requests/approve_change_request/', 'POST', {
    data: { change_request_id: id }
  })

export const requestNewSeat = async (
  token: string | undefined,
  organization_id: number,
  location_id: number,
  plan_id: number,
  start_date?: string
) =>
  axios
    .post(
      `/api/v1/change-requests/request_new_seat/`,
      {
        organization_id,
        location_id,
        plan_id,
        start_date
      },
      {
        headers: { Authorization: `Token ${token}` }
      }
    )
    .then((result: any) => {
      return result
    })

export const inviteTeamMember = (
  token: string | undefined,
  organization_id: number,
  invitation_email: string,
  organization_seat_id?: number
) =>
  axios
    .post(
      `/api/v1/invitations/team_member_invite/`,
      {
        organization_id,
        organization_seat_id,
        invitation_email
      },
      {
        headers: { Authorization: `Token ${token}` }
      }
    )
    .then((result: any) => {
      return result
    })

export const reSendInvitationEmail = (invite_token: string) =>
  request(
    `/api/v1/invitations/${invite_token}/resend_invitation_email/`,
    'POST',
    {}
  )

export const cancelTeamInvite = (invite_token: string) =>
  request(`/api/v1/invitations/${invite_token}/cancel_invite/`, 'POST', {})

export const acceptExistingMemberInvite = (
  invite_token: string,
  data: { job_title: string; company_name?: string | undefined }
) => {
  return request(
    `/api/v1/invitations/${invite_token}/accept_existing_user_invite/`,
    'POST',
    { data: data }
  )
}
export const validateInvite = (invite_token: string) =>
  axios
    .get(`/api/v1/invitations/${invite_token}/validate_invite_token/`)
    .then((result: any) => {
      return result
    })

export const setOrgUnitAdmin = (orgUnitId: number, isAdmin: boolean) =>
  request(`/api/v1/organizational-units/${orgUnitId}/`, 'PATCH', {
    data: { is_team_admin: isAdmin }
  })

export const updateOrgUnitJobTitle = (orgUnitId: number, job_title: string) =>
  request<String>(
    `/api/v1/organizational-units/${orgUnitId}/update_job_title/`,
    'PATCH',
    {
      data: { job_title }
    }
  )

export const updateDirectoryPreferences = (
  orgUnitId: number,
  data: IOrganizationalUnitDirectoryPreferences
) =>
  request<String>(
    `/api/v1/organizational-units/${orgUnitId}/update_directory_preferences/`,
    'PATCH',
    {
      data
    }
  )

export const assignMemberToSeat = (seatId: number, orgUnitId: number) =>
  request(`/api/v1/organization-seats/${seatId}/update_assignment/`, 'POST', {
    data: { organizational_unit_id: orgUnitId }
  })

export const clearSeatAssignment = (seatId: number) =>
  request(`/api/v1/organization-seats/${seatId}/update_assignment/`, 'POST', {})

export const initializeTeam = (
  teamName: string,
  adminEmails: string[],
  seats: Array<{ plan_id: number; value: number; override_reason?: string }>,
  offices: number[],
  isFirstSubscriptionNonChargeable: boolean,
  isEFTPermitted: boolean,
  locationId: number
) =>
  request(`/api/v1/onboard/`, 'POST', {
    data: {
      company: teamName,
      emails: adminEmails,
      seats,
      offices,
      is_first_subscription_non_chargeable: isFirstSubscriptionNonChargeable,
      is_eft_permitted: isEFTPermitted,
      location: locationId
    }
  })

export const updateOffice = (
  officeId: number,
  monthlyRate: number,
  creditRate: number,
  xeroAccountCode: number
) =>
  request(`/api/v1/offices/${officeId}/`, 'PATCH', {
    data: {
      rate: monthlyRate,
      meeting_credit_rate: creditRate,
      xero_account_code: xeroAccountCode
    }
  })

export const createOffice = (officeData: INewOffice) =>
  request(`/api/v1/offices/`, 'POST', {
    data: officeData
  })

export const createAddonSubscription = (
  addonSubscriptionRequest: IAddonSubscriptionRequest
) =>
  request(`api/v1/change-requests/request_new_addon_plan/`, 'POST', {
    data: addonSubscriptionRequest
  })

export const assignOffice = (
  officeId: number,
  organizationId: number,
  startDate: Date
) =>
  request(`/api/v1/offices/${officeId}/assign_office/`, 'POST', {
    data: {
      organization_id: organizationId,
      start_date: `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    }
  })

export const unassignOffice = (officeId: number) =>
  request(`/api/v1/offices/${officeId}/unassign_office/`, 'POST', {})

export const removeMember = (
  orgUnitId: number,
  action: string,
  assignee?: number
) =>
  request(`/api/v1/organizational-units/${orgUnitId}/remove_member/`, 'POST', {
    data: {
      action,
      assignee
    }
  })

export const regenerateWifiPassword = (userProfileId: number) =>
  request<{ wifi_password: string }>(
    `/api/v1/profile/${userProfileId}/regenerate_wifi_password/`,
    'POST',
    {}
  )
export const getPlanCategories = (tenantId: number) =>
  request(
    `/api/v1/platform-tenants/${tenantId}/plan_categories/`,
    'GET'
  ) as Promise<AxiosResponse<IPlanCategory[]>>

export const createPlanCategory = (
  tenantId: number,
  planCategoryInput: IPlanCategoryInput
) =>
  request<PlanCategory>(
    `/api/v1/platform-tenants/${tenantId}/plan_categories/`,
    'post',
    {
      data: planCategoryInput
    }
  )

export const updatePlanCategory = (
  tenantId: number,
  planCategory: PlanCategory
) =>
  request<PlanCategory>(
    `/api/v1/platform-tenants/${tenantId}/plan_categories/`,
    'patch',
    {
      data: planCategory
    }
  )

export const requestPlanCancellation = (seatId: number, cancelDate: Date) =>
  request(`/api/v1/change-requests/request_cancel_plan/`, 'POST', {
    data: {
      seat: seatId,
      change_date: format(cancelDate, 'yyyy-MM-dd')
    }
  })

export const requestAddonPlanCancellation = (
  subscriptionManagerId: number,
  cancelDate: Date
) =>
  request(`/api/v1/change-requests/request_cancel_addon_plan/`, 'POST', {
    data: {
      subscription_manager: subscriptionManagerId,
      change_date: format(cancelDate, 'yyyy-MM-dd')
    }
  })

export const requestPlanChange = (
  seatId: number,
  planId: number,
  changeDate: Date
) =>
  request(`/api/v1/change-requests/request_change_plan/`, 'POST', {
    data: {
      seat: seatId,
      new_plan: planId,
      change_date: format(changeDate, 'yyyy-MM-dd')
    }
  })

export const requestAccountDeletion = (user: number) =>
  request<AccountDeletionResponse>(
    `/api/v1/change-requests/request_user_deletion/`,
    'POST',
    {
      data: {
        user
      }
    }
  )

export const declineChangeRequest = (requestId: number) =>
  request(
    `/api/v1/change-requests/${requestId}/decline_change_request/`,
    'POST',
    {}
  )

export const approveChangeRequest = ({
  requestId,
  overrideValue,
  isFirstSubscriptionNonChargeable,
  nonChargeable,
  IsNonTaxable,
  overrideReason,
  location,
  plan,
  startDate
}: {
  requestId: number
  overrideValue?: number
  isFirstSubscriptionNonChargeable?: boolean
  nonChargeable?: boolean
  IsNonTaxable?: boolean
  overrideReason?: string
  location?: number
  plan?: number
  startDate?: string
}) =>
  request(
    `/api/v1/change-requests/${requestId}/approve_change_request/`,
    'POST',
    {
      data: {
        props: {
          override_value: overrideValue,
          is_first_subscription_non_chargeable:
            isFirstSubscriptionNonChargeable,
          non_chargeable: nonChargeable,
          is_non_taxable: IsNonTaxable,
          override_reason: overrideReason,
          location: location,
          plan: plan,
          start_date: startDate
        }
      }
    }
  )

export const revalidateUserDeletionRequestData = ({
  requestId
}: {
  requestId: number
}) =>
  request<IAccountDeletionRequest>(
    `/api/v1/change-requests/${requestId}/revalidate_user_deletion_data/`,
    'GET'
  )

export function fetchPaginatedPlanChangesReport(
  start: Date,
  end: Date,
  type: 'cancellation' | 'change',
  page: number,
  page_size: number,
  tenantId: number
) {
  const params = encodeQueryParams(planChangesReportQueryParams, {
    start,
    end,
    type,
    page,
    page_size,
    tenant: tenantId
  })
  return request<IPaginatedQuery<IPlanChangeRequest>>(
    `/api/v1/reports/plan-changes/?${stringify(params)}`,
    'GET'
  )
}

export const cancelBooking = (bookingId: number) =>
  request(`/api/v1/bookings/${bookingId}/cancel/`, 'POST', {})

export const updateBookingExtraDetails = (
  bookingId: number,
  notes?: string,
  event_sheet_url?: string
) =>
  request(`/api/v1/bookings/${bookingId}/update_extra_details/`, 'POST', {
    data: {
      notes,
      event_sheet_url
    }
  })

export const createConnection = ({
  memberAId,
  memberBId,
  message,
  connectedBy
}: {
  memberAId: number
  memberBId: number
  message?: string
  connectedBy: number
}) =>
  request(`/api/v1/daily-five/connections/`, 'POST', {
    data: {
      member_ids: [memberAId, memberBId],
      message,
      connected_by: connectedBy
    }
  })
export const createMemberDirectoryConnection = (
  orgUnitAId: number,
  orgUnitBId: number,
  message?: string
) =>
  request<string>(`/api/v1/members-directory/connections/`, 'POST', {
    data: {
      requested_by: orgUnitAId,
      requested_to: orgUnitBId,
      message
    }
  })
export const removeMemberDirectoryConnection = (connectionId: number) =>
  request<string>(
    `/api/v1/members-directory/connections/${connectionId}/`,
    'DELETE'
  )
export const fetchMyConnections = (
  page: number,
  page_size: number,
  orgUnit: number,
  filter: string
) => {
  const params = encodeQueryParams(filterMyConnectionsQueryParams, {
    page: page,
    page_size,
    filter,
    orgUnit
  })

  return request<IPaginatedQuery<DirectoryMemberResult>>(
    `/api/v1/members-directory/connections/?${stringify(params)}`,
    'GET'
  )
}

export const viewConnection = (connectionId: number) =>
  request(`/api/v1/daily-five/connections/${connectionId}/view/`, 'POST', {})

export const creditCurrentCharges = (organizationId: number) =>
  request(
    `/api/v1/organizations/${organizationId}/credit_current_charges/`,
    'POST',
    {}
  )

export const updateProfilePicture = (
  data: { image?: File },
  userProfileId: number
) => {
  const formData = new FormData()
  formData.append('image', data.image as Blob)

  return request(`/api/v1/profile/${userProfileId}/profile_picture/`, 'POST', {
    data: formData
  })
}

export const deleteProfilePicture = (userProfileId: number) => {
  return request(`/api/v1/profile/${userProfileId}/profile_picture/`, 'DELETE')
}

export const updatePlan = (planId: number, planData: Partial<INewPlan>) => {
  const fData = new FormData()
  planData.name && fData.append('name', planData.name)
  planData.category && fData.append('category', planData.category.toString())
  planData.description && fData.append('description', planData.description)
  planData.order_of_preference &&
    fData.append('order_of_preference', planData.order_of_preference.toString())
  planData.hidden !== undefined &&
    fData.append('hidden', planData.hidden.toString())
  planData.hidden_to_admin !== undefined &&
    fData.append('hidden_to_admin', planData.hidden_to_admin.toString())
  planData.addon_plan &&
    fData.append('addon_plan', planData.addon_plan.toString())
  planData.approve_on_sign_up !== undefined &&
    fData.append('approve_on_sign_up', planData.approve_on_sign_up.toString())
  planData.inclusions_document_file &&
    fData.append(
      'inclusions_document_file',
      planData.inclusions_document_file as Blob
    )
  return request(`/api/v1/plans/${planId}/`, 'PATCH', {
    data: fData
  })
}

export const createPlan = (planData: INewPlan) => {
  const fData = new FormData()
  // tslint:disable: no-unused-expression
  planData.category && fData.append('category', planData.category.toString())
  planData.description && fData.append('description', planData.description)
  planData.frequency && fData.append('frequency', planData.frequency)
  planData.hidden && fData.append('hidden', planData.hidden.toString())
  planData.hidden_to_admin &&
    fData.append('hidden_to_admin', planData.hidden_to_admin.toString())
  planData.location && fData.append('location', planData.location.toString())
  planData.tenant && fData.append('tenant', planData.tenant.toString())
  planData.meeting_room_credits_included &&
    fData.append(
      'meeting_room_credits_included',
      planData.meeting_room_credits_included.toString()
    )
  planData.name && fData.append('name', planData.name)
  planData.order_of_preference &&
    fData.append('order_of_preference', planData.order_of_preference.toString())
  planData.value && fData.append('value', planData.value.toString())
  planData.inclusions_document_file &&
    fData.append(
      'inclusions_document_file',
      planData.inclusions_document_file as Blob
    )
  planData.addon_plan &&
    fData.append('addon_plan', planData.addon_plan.toString())
  return request(`/api/v1/plans/`, 'POST', {
    data: fData
  })
}

export const createOrUpdateLocationAdmin = (data: ILocationAdminDTO) => {
  return request<ILocationAdmin>(`/api/v1/locations-admins/`, 'POST', {
    data
  })
}

export const uploadBookingConfirmationPDF = (
  location: number,
  remove: boolean,
  document: any | undefined
) => {
  const fData = new FormData()
  if (remove) {
    fData.append('remove', 'True')
  } else {
    fData.append('booking_confirmation_file', document as Blob)
  }
  return request<string>(
    `/api/v1/locations/${location}/update_booking_confirmation_document/`,
    'POST',
    {
      data: fData
    }
  )
}
export const getPlanByUUID = (uuid: string) =>
  request<Plan>(`/api/v1/plans/get_plan_by_uuid/?plan_uuid=${uuid}`, 'GET')
export const updateMeetingRoom = (
  meetingRoomId: number,
  data: Partial<INewMeetingRoom>
) => {
  const fData = new FormData()
  // data.private_room &&
  fData.append(
    'private_room',
    data.private_room ? data.private_room.toString() : 'false'
  )
  fData.append(
    'public_booking',
    data.public_booking ? data.public_booking.toString() : 'false'
  )
  data.allowed_plans &&
    fData.append('allowed_plans', JSON.stringify(data.allowed_plans))
  data.plan_override_pricing &&
    fData.append(
      'plan_override_pricing',
      JSON.stringify(data.plan_override_pricing)
    )
  data.allowed_offices &&
    fData.append('allowed_offices', JSON.stringify(data.allowed_offices))
  data.name && fData.append('name', data.name)
  data.about && fData.append('about', data.about)
  fData.append(
    'has_videoconference',
    data.has_videoconference ? 'true' : 'false'
  )
  fData.append(
    'has_screen_mirroring',
    data.has_screen_mirroring ? 'true' : 'false'
  )
  fData.append('capacity', data.capacity + '')

  data.xero_account_code &&
    fData.append('xero_account_code', data.xero_account_code.toString())

  data.display_image_file &&
    fData.append('display_image_file', data.display_image_file as Blob)

  data.remove_image !== undefined &&
    fData.append('remove_image', data.remove_image.toString())

  return request(`/api/v1/meeting-rooms/${meetingRoomId}/`, 'PATCH', {
    data: fData
  })
}
export const updateWorkspace = (
  workspaceID: number,
  data: Partial<INewWorkspace>
) => {
  const fData = new FormData()
  fData.append('name', data.name ? data.name.toString() : 'false')
  fData.append(
    'is_bookable',
    data.is_bookable ? data.is_bookable.toString() : 'false'
  )

  data.xero_account_code &&
    fData.append('xero_account_code', data.xero_account_code.toString())

  return request(`/api/v1/workspaces/${workspaceID}/`, 'PATCH', {
    data: fData
  })
}

export const createWorkspace = (data: INewWorkspace) => {
  const fData = new FormData()
  data.name && fData.append('name', data.name.toString())
  data.location && fData.append('location', data.location.toString())
  data.is_main_space &&
    fData.append('is_main_space', data.is_main_space.toString())
  data.is_bookable && fData.append('is_bookable', data.is_bookable.toString())
  data.xero_account_code &&
    fData.append('xero_account_code', data.xero_account_code.toString())
  data.member_price_half_day_price &&
    fData.append(
      'member_price_half_day_price',
      data.member_price_half_day_price.toString()
    )
  data.member_price_full_day_price &&
    fData.append(
      'member_price_full_day_price',
      data.member_price_full_day_price.toString()
    )
  data.non_member_price_half_day_price &&
    fData.append(
      'non_member_price_half_day_price',
      data.non_member_price_half_day_price.toString()
    )
  data.non_member_price_full_day_price &&
    fData.append(
      'non_member_price_full_day_price',
      data.non_member_price_full_day_price.toString()
    )
  return request(`/api/v1/workspaces/`, 'POST', {
    data: fData
  })
}

export const createMeetingRoom = (data: INewMeetingRoom) => {
  const fData = new FormData()
  fData.append('xero_account_code', data.xero_account_code.toString())
  data.non_member_price_per_hour &&
    fData.append(
      'non_member_price_per_hour',
      data.non_member_price_per_hour.toString()
    )
  data.about && fData.append('about', data.about)
  data.private_room &&
    fData.append('private_room', data.private_room.toString())
  data.public_booking &&
    fData.append('public_booking', data.public_booking.toString())
  data.allowed_plans &&
    fData.append('allowed_plans', JSON.stringify(data.allowed_plans))
  data.plan_override_pricing &&
    fData.append(
      'plan_override_pricing',
      JSON.stringify(data.plan_override_pricing)
    )
  data.allowed_offices &&
    fData.append('allowed_offices', JSON.stringify(data.allowed_offices))
  data.credits_per_hour !== undefined &&
    fData.append('credits_per_hour', data.credits_per_hour.toString())
  data.name && fData.append('name', data.name)
  data.member_price_per_hour !== undefined &&
    fData.append('member_price_per_hour', data.member_price_per_hour.toString())
  data.non_member_price_per_hour !== undefined &&
    fData.append(
      'non_member_price_per_hour',
      data.non_member_price_per_hour.toString()
    )
  fData.append(
    'has_videoconference',
    data.has_videoconference ? 'true' : 'false'
  )
  fData.append(
    'has_screen_mirroring',
    data.has_screen_mirroring ? 'true' : 'false'
  )
  fData.append('capacity', data.capacity + '')
  data.location && fData.append('location', data.location.toString())

  data.display_image_file &&
    fData.append('display_image_file', data.display_image_file as Blob)
  return request(`/api/v1/meeting-rooms/`, 'POST', {
    data: fData
  })
}

// Content Explorer
export const fetchSearchtags = () => {
  return request(
    '/api/v1/content-explorer/content/display_tags/',
    'GET'
  ) as Promise<AxiosResponse<Interest[]>>
}

export function fetchContentExplorerContent(
  tenantId: number | undefined,
  tags: Interest[],
  page: number
) {
  return request(
    `/api/v1/content-explorer/content/fetch_content/?page=${page}&page_size=6`,
    'POST',
    {
      data: { platform_tenant: tenantId, tags }
    }
  ) as Promise<AxiosResponse<IPaginatedDailyFive>>
}

export const fetchMyPosts = (tenantId: number, page: number) =>
  request(
    `/api/v1/content-explorer/content/fetch_my_posts/?page=${page}`,
    'POST',
    {
      data: { platform_tenant: tenantId }
    }
  ) as Promise<AxiosResponse<IPaginatedDailyFive>>

export const updateContent = (data: INewContent, contentId: number) => {
  const fData = new FormData()
  fData.append('title', data.title)
  fData.append('content_type', data.content_type)
  data.summary && fData.append('summary', data.summary)
  data.url && fData.append('url', data.url)
  data.date && fData.append('date', data.date)
  data.start_time && fData.append('start_time', data.start_time)
  data.finish_time && fData.append('finish_time', data.finish_time)
  data.location && fData.append('location', data.location)
  data.location_venue &&
    fData.append('location_venue', `${data.location_venue.id}`)
  data.platform_tenant &&
    fData.append('platform_tenant', data.platform_tenant.toString())
  data.interests && fData.append('interests', JSON.stringify(data.interests))
  data.image && fData.append('image', data.image as Blob)

  return request(
    `/api/v1/daily-five/content/${contentId}/edit_content/`,
    'POST',
    {
      data: fData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  ) as Promise<AxiosResponse<string>>
}

export const removeContent = (contentId: number) => {
  return request(
    `/api/v1/daily-five/content/${contentId}/remove_content/`,
    'POST'
  ) as Promise<AxiosResponse<string>>
}

// EVENTS CALENDAR
export const getMonthlyEvents = (
  tenantId: number | undefined,
  start_date: Date,
  end_date: Date,
  filterData: IEventsFilter
) => {
  let startDateStr = format(start_date, 'dd-MM-yyyy')
  let endDateStr = format(end_date, 'dd-MM-yyyy')
  const params = encodeQueryParams(filterCalendarEventsQueryParams, {
    tenantId: tenantId,
    location: filterData.location,
    interests: filterData.interests
  })
  return request(
    `/api/v1/daily-five/events/?${stringify(
      params
    )}&start_date=${startDateStr}&end_date=${endDateStr}`,
    'GET'
  ) as Promise<AxiosResponse<IDailyFiveContent[]>>
}
// QUICKFIX

// This approach is not intended to be scalable, we are using this method of querying blog posts for the core onboarding
// future scopes of work should build out the daily five/blog posts fully and this will need to change
export const getCoreBlogPosts = () => {
  return request('/api/v1/blog-posts/core_blog/', 'GET') as Promise<
    AxiosResponse<IBlogPost[]>
  >
}

export const getSpacecubedBlogPosts = () => {
  return request('/api/v1/blog-posts/', 'GET') as Promise<
    AxiosResponse<IBlogPost[]>
  >
}

export const updateEFTAccount = (
  tenantId: number,
  data: Partial<TenantEftAccount>
) =>
  request(`/api/v1/platform-tenants/${tenantId}/eft_account/`, 'PATCH', {
    data
  })

export const getSetupIntent = (tenantId: number) =>
  request<{ client_secret: string; tenant_id: string }>(
    `api/v1/payment-gateway/get-setup-intent/?tenant=${tenantId}`,
    'get'
  )

export const updateAndRemoveAccountCode = (
  tenantId: number,
  data: {
    xero_account_to_remove_id: string
    xero_account_to_replace_with_id: string
  }
) =>
  request(
    `/api/v1/platform-tenants/${tenantId}/bulk_update_replace_account_code/`,
    'POST',
    {
      data
    }
  )

export const deleteXeroConnectionForTenant = (tenantId: number) =>
  request(
    `/api/v1/platform-tenants/${tenantId}/delete_xero_connection/`,
    'DELETE'
  )
export const validateTenantInvite = (invite_token: string) =>
  request<IInvitation>(
    `/api/v1/invitations/${invite_token}/validate_invite_tenant_token/`,
    'GET'
  )

// Paginated, filterable requests
export const fetchPaginatedOrganizationsByName = (
  tenantId: number,
  name?: string,
  location?: string,
  plan?: number,
  page?: number,
  page_size?: number
) => {
  const params = encodeQueryParams(filterOrganizationQueryParams, {
    page: page ?? 1,
    tenant: tenantId,
    name,
    location,
    plan,
    page_size
  })

  return request<IPaginatedQuery<IOrganization>>(
    `/api/v1/organizations/?${stringify(params)}`,
    'GET'
  )
}

export const fetchPaginatedOrgsInMembersDirectory = (
  orgUnitId: number,
  textFilter?: string,
  page?: number,
  page_size?: number
) => {
  return request<IPaginatedQuery<DirectoryOrganizationResult>>(
    `/api/v1/members-directory/search_organizations/?page=${page}&page_size=${page_size}`,
    'POST',
    {
      data: {
        org_unit: orgUnitId,
        text_filter: textFilter
      }
    }
  )
}
export const fetchPaginatedMembersInMembersDirectory = (
  orgUnitId: number,
  textFilter?: string,
  skillsFilter?: number[],
  page?: number,
  page_size?: number
) => {
  return request<IPaginatedQuery<DirectoryOrganizationalUnitResult>>(
    `/api/v1/members-directory/search_members/?page=${page}&page_size=${page_size}`,
    'POST',
    {
      data: {
        org_unit: orgUnitId,
        text_filter: textFilter,
        skills_filter: skillsFilter
      }
    }
  )
}

export const filterOrganizationalUnits = (
  tenantId: number,
  name?: string,
  location?: string,
  plan?: number,
  organization?: number,
  page?: number,
  page_size?: number
) => {
  const params = encodeQueryParams(filterOrganizationalUnitQueryParams, {
    page: page ?? 1,
    tenant: tenantId,
    name,
    plan,
    organization,
    location,
    page_size
  })
  return request<IPaginatedQuery<IOrganizationalUnit>>(
    `/api/v1/organizational-units/?detail=basic&${stringify(params)}`,
    'GET'
  )
}
export const filterSelectOrganizationalUnits = (
  tenantId: number,
  name?: string,
  organization?: number,
  page?: number,
  page_size?: number
) => {
  const params = encodeQueryParams(filterOrganizationalUnitQueryParams, {
    page: page ?? 1,
    tenant: tenantId,
    name,
    organization,
    page_size
  })
  return request<IPaginatedQuery<IOrganizationalUnitFilterableSelect>>(
    `/api/v1/organizational-units/?detail=is_member&${stringify(params)}`,
    'GET'
  )
}

export const filterOffices = (
  tenantId: number,
  name?: string,
  page?: number,
  page_size?: number
) => {
  const params = encodeQueryParams(filterOfficesQueryParams, {
    page: page ?? 1,
    tenant: tenantId,
    name,
    page_size
  })
  return request<IPaginatedQuery<IBasicOffice>>(
    `/api/v1/offices/?is_basic=1&${stringify(params)}`,
    'GET'
  )
}

// gets locations with public meeting rooms and excludes tenant's locations
export const fetchBookableMeetingRoomLocations = (
  tenantId: number,
  public_filter: boolean,
  nameFilter: string | undefined,
  page: number
) => {
  const params = encodeQueryParams(filterLocationsQueryParams, {
    page: page ?? 1,
    tenantId,
    nameFilter
  })
  return request<IPaginatedQuery<Location>>(
    `/api/v1/locations/get_bookable_locations/?${stringify(params)}`,
    'GET'
  )
}
