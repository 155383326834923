import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Stack,
  useTheme,
  Tab,
  Flex,
  Box,
  Divider,
  useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { IOrganizationSeat, Plan, Location } from '../../../../types'
import { KeyValueText } from '../../../../components/KeyValueText'

import Calendar from 'react-calendar'
import { addDays, format, endOfMonth } from 'date-fns'
import { usePlans } from '../../../../utils/apiHooks'
import { ConfirmCancellationModal } from './ConfirmCancellationModal'
import { ConfirmChangeModal } from './ConfirmChangeModal'
import { useAuth } from '../../../../context/auth-context'
import styled from '@emotion/styled'
import { usePlatformTenant } from '../../../../context/platform-tenant-context'
import { FilterableSelect } from '../../../../components/FilterableSelect'

interface IModalInput {
  seat?: IOrganizationSeat
  closeModalCallback: () => void
}

interface ITabMapping {
  tabName: string
  buttonText: string
  action: () => void
}

export const ManageMembershipModal: React.FC<IModalInput> = (input) => {
  const auth = useAuth()
  const { seat, closeModalCallback } = input
  const { platformTenant } = usePlatformTenant()
  const { plans } = usePlans(platformTenant?.id)
  const [chosenLocation, setChosenLocation] = useState<Location | null>(null)
  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([])

  const [tabIndex, setTabIndex] = useState<number>(0)

  const [changePlan, setChangePlan] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [changeDate, setChangeDate] = useState<Date>()
  const toast = useToast()

  const [cancelPlan, setCancelPlan] = useState(false)
  const [cancellationDate, setCancellationDate] = useState<Date>()
  const theme: any = useTheme()

  function resetModal() {
    setChangePlan(false)
    setCancelPlan(false)
    setSelectedPlan(undefined)
    setChangeDate(undefined)
    setCancellationDate(undefined)
  }

  function closeModal() {
    resetModal()
    closeModalCallback()
  }

  const tabMapping: ITabMapping[] = [
    {
      tabName: 'Change Plan',
      buttonText: 'Request Plan Change',
      action: () => {
        if (changeDate && selectedPlan) {
          setChangePlan(true)
        } else {
          toast({
            title: 'Incomplete Information',
            description: 'Please select a plan and a Change date.',
            status: 'error'
          })
        }
      }
    },
    {
      tabName: 'Cancel Plan',
      buttonText: 'Request Plan Cancellation',
      action: () => {
        if (cancellationDate) {
          setCancelPlan(true)
        } else {
          toast({
            title: 'Incomplete Information',
            description: 'Please select valid Cancellation date.',
            status: 'error'
          })
        }
      }
    }
  ]
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  if (!!!seat) {
    return null
  }
  if (cancelPlan && cancellationDate) {
    return (
      <ConfirmCancellationModal
        seat={seat}
        cancellationDate={cancellationDate}
        closeModalCallback={closeModal}
      />
    )
  }
  if (changePlan && changeDate && selectedPlan) {
    return (
      <ConfirmChangeModal
        seat={seat}
        newPlan={selectedPlan}
        changeDate={changeDate}
        closeModalCallback={closeModal}
      />
    )
  }

  const handleLocationMenuClick = (location: Location) => {
    if (location) {
      setChosenLocation(location)
      const plansToShow = plans.filter(
        (p) =>
          (p.location?.id === location.id || p.location === null) &&
          // This ensures the plan is a valid plan (not an addon plan),
          // prevents improper DB state
          !p.addon_plan
      )
      setFilteredPlans(plansToShow)
      location !== chosenLocation && setSelectedPlan(undefined)
    }
  }

  // allow space admins to cancel plans at the end of the current month
  // all other users must follow the 30 day rule
  const minimumCancellationDate = () => {
    if (auth.me?.user.is_space_admin) {
      return endOfMonth(new Date())
    }
    return addDays(new Date(), 30)
  }

  return (
    <BaseModal
      title="Manage Membership"
      isOpen={seat !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={tabMapping[tabIndex].buttonText}
      primaryAction={tabMapping[tabIndex].action}
      secondaryAction={closeModal}
      size="3xl"
    >
      <Flex>
        <Box w="60%">
          <Tabs mt={6} mb={4} onChange={(index) => setTabIndex(index)}>
            <TabList>
              {tabMapping.map((tab, i) => (
                <StyledTab key={i}>{tab.tabName}</StyledTab>
              ))}
            </TabList>

            <TabPanels mt={2}>
              <TabPanel>
                <FilterableSelect
                  dataTestId="location-button"
                  items={platformTenant?.locations.map((location) => ({
                    label: location.name,
                    value: location.id
                  }))}
                  name={'Choose a Location'}
                  onSelect={(n) => {
                    let location = platformTenant?.locations.find(
                      (l) => l.id === n
                    )
                    if (location) {
                      handleLocationMenuClick(location)
                    }
                  }}
                />
                <FilterableSelect
                  items={filteredPlans.map((plan) => ({
                    label: plan.name,
                    value: plan.id
                  }))}
                  name="Select a plan"
                  initialValue={selectedPlan?.id}
                  onSelect={(n) =>
                    setSelectedPlan(plans.find((plan) => plan.id === n))
                  }
                />
                <Stack isInline mb={2}>
                  <Text fontWeight="bold" fontSize="14px">
                    Date to change plans:{' '}
                  </Text>
                  <Text fontSize="14px">
                    {changeDate ? format(changeDate, 'dd/MM/yyyy') : ''}
                  </Text>
                </Stack>
                <Calendar
                  selectRange={false}
                  onChange={(date) => {
                    setChangeDate(
                      Array.isArray(date) ? (date[0] as Date) : (date as Date)
                    )
                  }}
                  value={changeDate}
                  minDate={new Date()}
                />
              </TabPanel>

              <TabPanel>
                <Stack isInline mb={2}>
                  <Text color=" #2f4858" fontWeight="bold" fontSize="14px">
                    Select the cancellation date for this membership:{' '}
                  </Text>
                  <Text fontSize="14px">
                    {cancellationDate
                      ? format(cancellationDate, 'dd/MM/yyyy')
                      : ''}
                  </Text>
                </Stack>
                <Calendar
                  selectRange={false}
                  onChange={(date) => {
                    setCancellationDate(
                      Array.isArray(date) ? (date[0] as Date) : (date as Date)
                    )
                  }}
                  value={cancellationDate}
                  minDate={minimumCancellationDate()}
                />
                <Text as={'i'}>
                  Note: 30 Days notice must be given when cancelling memberships
                </Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Stack spacing={3} p={4} w="40%">
          <Box h={'57px'} />
          <KeyValueText
            wrap={true}
            title="Current Plan"
            value={seat.active_plan.name}
          />
          <Divider />
          <KeyValueText
            wrap={true}
            title="Future Plan"
            value={
              seat.ongoing_plan ? seat.ongoing_plan.name : seat.active_plan.name
            }
          />
          <Divider />
          <KeyValueText
            wrap={true}
            title="Assigned Member"
            value={
              seat.organizational_unit
                ? seat.organizational_unit.user?.name
                : 'Unassigned'
            }
          />
          <Divider />
        </Stack>
      </Flex>
    </BaseModal>
  )
}
