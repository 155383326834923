import { Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { IOrganizationSeat } from '../../../../types'
import { format } from 'date-fns'
import { requestPlanCancellation } from '../../../../api'

interface IModalInput {
  seat?: IOrganizationSeat
  cancellationDate: Date
  closeModalCallback: () => void
}

export const ConfirmCancellationModal: React.FC<IModalInput> = (input) => {
  const { seat, cancellationDate, closeModalCallback } = input
  const [isSubmitting, setIsSubmitting] = useState(false)
  const toast = useToast()

  function closeModal() {
    closeModalCallback()
  }

  function requestCancellation() {
    if (isSubmitting) return
    setIsSubmitting(true)
    if (seat && cancellationDate) {
      requestPlanCancellation(seat.id, cancellationDate)
        .then(() => {
          setIsSubmitting(false)
          toast({
            status: 'success',
            description: `Plan cancellation request sent`
          })
          closeModal()
        })
        .catch(() => {
          setIsSubmitting(false)

          toast({
            status: 'error',
            description: `Failed to send cancellation request`
          })
        })
    }
  }

  if (!!!seat) {
    return null
  }
  return (
    <BaseModal
      title="Confirm Cancellation"
      isOpen={seat !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={'Confirm'}
      primaryAction={requestCancellation}
      secondaryAction={closeModal}
      isSubmitting={isSubmitting}
      isLoading={isSubmitting}
    >
      <Text>
        Are you sure you want to cancel your membership on{' '}
        {format(cancellationDate, 'dd/MM/yyyy')}?
      </Text>
    </BaseModal>
  )
}
